import { getAppInsights } from "@src/TelemetryProvider";
import { ArgumentNullError, AuthorizationError, NotFoundError, ValidationError } from "./exceptions";
import jwt from "./jwt";

const authenticatedDomains = process.env.AUTHENTICATED_HOSTNAMES.split(",");
const originalFetch = window.fetch;

window.fetch = async (input, init) => {
    if (!init) {
        init = {};
    }
    if (!init.headers) {
        init.headers = new Headers();
    }
    if (!input || input.toString().indexOf("null") === 0 || input.toString().indexOf("undefined") === 0) {
        try {
            throw new ArgumentNullError("input");
        }
        catch (exception) {
            getAppInsights().trackException({
                exception: exception as Error
            });
            throw exception;
        }
    }

    if (init.body && typeof init.body === "string" && (init.body.startsWith("{") || init.body.startsWith("["))) {
        // if body is a json string, set content type to json if missing.

        // init.headers could be:
        //   `A Headers object, an object literal,
        //    or an array of two-item arrays to set request’s headers.`
        if (init.headers instanceof Headers && !init.headers.has('Content-Type')) {
            init.headers.append('Content-Type', "application/json");
        } else if (init.headers instanceof Array && !init.headers.some(h => h[0] === 'Content-Type')) {
            init.headers.push(['Content-Type', "application/json"]);
        } else {
            // object ?
            const headers = init.headers as { [key: string]: string };
            if (!headers['Content-Type'])
                headers['Content-Type'] = "application/json";
        }
    }

    const url = input instanceof URL ? input : input instanceof Request ? new URL(input.url) : new URL(input.toString(), input.toString().startsWith("/") ? window.location.origin : undefined);
    const shouldAuthorize = authenticatedDomains.indexOf(url.hostname) > -1;

    if (shouldAuthorize) {
        try {
            const token = await jwt.getToken();

            if (!jwt.hasValidToken())
                jwt.redirectToLogin("no valid token"); // Should we redirect to login in auth failures? 

            // init.headers could be: 
            //   `A Headers object, an object literal, 
            //    or an array of two-item arrays to set request’s headers.`
            if (init.headers instanceof Headers) {
                init.headers.append('Authorization', `Bearer ${token}`);
            } else if (init.headers instanceof Array) {
                init.headers.push(['Authorization', `Bearer ${token}`]);
            } else {
                // object ?
                init.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        catch (e) {
            if (e instanceof Error) {
                getAppInsights().trackException({
                    exception: e
                });
                jwt.redirectToLogin("auth service failure"); // Should we redirect to login in auth failures? 
            }
            else
                throw e;
        }
    }
    let result: Response;
    try {
        result = await originalFetch(input as Request, init);
        await result.ensureStatus();
    }
    catch (exception) {
        (exception as Error).message += ` url: ${input.toString()}`;
        getAppInsights().trackException({
            exception: exception as Error
        });
        throw exception;
    }
    return result;
};

window.Response.prototype.ensureStatus = async function (this: Response) {
    if (this.status === 404)
        throw new NotFoundError();
    if (this.status >= 401 && this.status <= 403) {
        const contentType = this.headers.get("content-type");
        const isJson = contentType && contentType === "application/json";

        const obj = isJson ? (await this.json()) as { url: string } : null;
        const text = !isJson ? { message: await this.text() } : null;
        if (obj?.url) {
            const telemetry = getAppInsights();
            telemetry.trackEvent({ name: "redirect to url", properties: { url: obj.url } });
            telemetry.flush(false);
            window.location.href = obj.url;
        }
        throw new AuthorizationError(obj || text || undefined);
    }
    if (this.status >= 400 && this.status < 500)
        throw new ValidationError(await this.json() || undefined);
    if (this.status < 200 || this.status >= 500)
        throw new Error(await this.text() || "Empty response");
}
