import useNetworkStatus from "@src/customHooks/useNetworkStatus";
import { RoutePaths } from "@src/Routes";
import { WithRouterProps, withRouter } from "@src/utils/withRouter";
import * as React from "react";
import { FC, useState } from "react";
import { connect } from "react-redux";
import { Dispatchers } from "../../../Dispatchers";
import { siteHeaderContext } from "../Domain/Store";
import { Notifications } from "./Notifications";
import { OrganizationLogo } from "./OrganizationLogo";
import { ProfileSelection } from "./ProfileSelection";

const dispatchers = {

};


interface Props extends WithRouterProps {

    showDistrictName: boolean;
    districtName?: string;
    organizationName: string;
    userName: string;
    userRoleName: string;
    organizationLogoUri: string;
    email: string;
    isPermissionClick: boolean;
    isDistrictAdministrator: boolean;
    dashboardUri: string;
    editionName: string;
}

const UserInfoComponent: FC<Props & Dispatchers<typeof dispatchers>> = (props) => {

    const { isOnline } = useNetworkStatus();
    const [showProfileSelection, setShowProfileSelection] = useState(false);

    function dashboardClicked(event: React.SyntheticEvent) {
        event.stopPropagation();

        props.navigate(RoutePaths.Landing);
    }


    function renderProfileSelection() {

        return showProfileSelection
            ? <ProfileSelection
                isDistrictAdministrator={props.isDistrictAdministrator}
                isPermissionClick={props.isPermissionClick}
                onCancel={() => setShowProfileSelection(false)}
            />
            : null;
    }


    const userName = `${props.userName} – ${props.userRoleName}`;
    const userTitleText = `${props.userName} – ${props.userRoleName}: ${props.email}`;
    const organizationNameCopy = props.editionName
        ? `${props.organizationName} – ${props.editionName} Edition`
        : props.organizationName;


    const classNames = ["user-info"];

    if (props.isPermissionClick
        || props.isDistrictAdministrator) {

        classNames.push("dashboard");
    }

    return <li className={classNames.join(" ")}>
        <div className="current-user" onClick={() => setShowProfileSelection(!showProfileSelection)}>
            <div className={`org-name ${(!props.showDistrictName || !props.districtName ? "district" : "")}`}>
                <p title={organizationNameCopy}>{organizationNameCopy}</p>
                {
                    props.showDistrictName
                        && props.districtName
                        ? <p title={props.districtName}>{props.districtName}</p>
                        : null
                }

                <p title={userTitleText}>{userName}</p>
            </div>
            <OrganizationLogo
                organizationLogoUri={props.organizationLogoUri}
                organizationName={props.organizationName}
            />
            {renderProfileSelection()}
        </div>
        {
            props.isPermissionClick
                || props.isDistrictAdministrator
                ? <i
                    title="Back to your dashboard"
                    className="fas fa-home-alt back-to-dashboard"
                    onClick={(event) => dashboardClicked(event)}
                >
                </i>
                : null
        }
        {<Notifications isOnline={isOnline} />}
    </li>;
};


export const UserInfo = withRouter(connect(
    (_state) => ({
    }),
    dispatchers,
    undefined,
    { context: siteHeaderContext }
)(UserInfoComponent));